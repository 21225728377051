import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import Carousel from 'react-bootstrap/Carousel';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

function Main() {
  const { ref, inView } = useInView({ triggerOnce: true });
  const [startCounter, setStartCounter] = useState(false);

  if (inView && !startCounter) {
    setStartCounter(true);
  }

  return (
    <div style={{ 
      background: 'linear-gradient(135deg,rgb(120, 188, 234),rgb(9, 71, 137))', 
      minHeight: '100vh', 
      padding: '80px 0' 
    }}>
      <Container fluid>
        {/* First Section: Smaller Image Carousel with Text on the Right */}
        <Row className="mb-5 align-items-center">
          {/* Left Column: Image Carousel */}
          <Col md={6} className="d-flex justify-content-center align-items-center">
            <Carousel style={{ 
              maxWidth: '70%', 
              borderRadius: '10px', 
              overflow: 'hidden', 
              width: '100%' 
            }}>
              <Carousel.Item>
                <div style={{ width: '100%', height: '350px', overflow: 'hidden' }}>
                  <img 
                    className="d-block w-100" 
                    src="images/picwmelissa.jpg" 
                    alt="First slide" 
                    style={{ 
                      objectFit: 'cover', 
                      width: '100%', 
                      height: '350px' 
                    }} 
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div style={{ width: '100%', height: '350px', overflow: 'hidden' }}>
                  <img 
                    className="d-block w-100" 
                    src="images/picwclaudia.jpeg" 
                    alt="Second slide" 
                    style={{ 
                      objectFit: 'cover', 
                      width: '100%', 
                      height: '350px' 
                    }} 
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div style={{ width: '100%', height: '350px', overflow: 'hidden' }}>
                  <img 
                    className="d-block w-100" 
                    src="images/kidlaptop.jpeg" 
                    alt="Third slide" 
                    style={{ 
                      objectFit: 'cover', 
                      width: '100%', 
                      height: '350px' 
                    }} 
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div style={{ width: '100%', height: '350px', overflow: 'hidden' }}>
                  <img 
                    className="d-block w-100" 
                    src="images/picwzac.jpeg" 
                    alt="Fourth slide" 
                    style={{ 
                      objectFit: 'cover', 
                      width: '100%', 
                      height: '350px' 
                    }} 
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>

          {/* Right Column: Larger Text */}
          <Col md={6} className="d-flex flex-column justify-content-center" style={{ padding: '0 50px' }}>
            <h1 className="poppins-bold" style={{ fontSize: '3rem', textAlign: 'center' }}>
              Making Computer Ownership Possible For All
            </h1>
          </Col>
        </Row>

        {/* Second Section: Impact So Far */}
        <Row className="mb-5 align-items-center flex-column text-center">
          <Col md={8} ref={ref}>
            <h3 className="poppins-bold mt-5 mb-5" style={{ fontSize: '2.5rem', marginTop: '100px', marginBottom: '100px' }}>
              Impact So Far
            </h3>
            <Row className="d-flex justify-content-center align-items-center" style={{ gap: '200px' }}>
              <Col md="auto" className="text-center">
                <p className="poppins-bold" style={{ fontSize: '2.5rem' }}>
                  <CountUp start={0} end={startCounter ? 20 : 0} duration={3} />
                </p>
                <p className="poppins-regular" style={{ fontSize: '2rem' }}>Laptops Donated</p>
              </Col>
              <Col md="auto" className="text-center">
                <p className="poppins-bold" style={{ fontSize: '2.5rem' }}>
                  <CountUp start={0} end={startCounter ? 50 : 0} duration={3} />
                </p>
                <p className="poppins-regular" style={{ fontSize: '2rem' }}>People Helped</p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Third Section: Partners */}
        <Row className="align-items-center flex-column text-left">
          <Col md={10} className="d-flex flex-column justify-content-left" style={{ padding: '0 50px' }}>
            {/* Enlarged "Partners" Text */}
            <h2 className="poppins-bold" style={{ fontSize: '2rem', marginBottom: '40px' }}>
              Partners
            </h2>

            {/* Row for Logos */}
            <Row className="align-items-center d-flex justify-content-center" style={{ gap: '50px' }}>
              {/* Partner 1 */}
              <Col md="auto" className="text-center">
                <a href="https://lcfs.org/" target="_blank" rel="noopener noreferrer">
                  <img src="images/lcfs-removebg-preview.png" alt="Lutheran Child and Family Services" style={{ width: '200px', height: 'auto' }} />
                </a>
              </Col>

              {/* Partner 2 */}
              <Col md="auto" className="text-center">
                <a href="https://www.thenightministry.org/" target="_blank" rel="noopener noreferrer">
                  <img src="images/nightmin.png" alt="Partner 2" style={{ width: '200px', height: 'auto' }} />
                </a>
              </Col>

              {/* Partner 3 */}
              <Col md="auto" className="text-center">
                <a href="https://chicagohomeless.org/" target="_blank" rel="noopener noreferrer">
                  <img src="images/logo-chicago-homeless.webp" alt="Partner 3" style={{ width: '200px', height: 'auto' }} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Fourth Section: Mission Statement */}
        <Row className="align-items-center flex-column text-left mt-7" style={{ marginTop: '60px' }}>
          <Col md={10} className="d-flex flex-column justify-content-left" style={{ padding: '0 50px' }}>
            <h2 className="poppins-bold">Our Mission</h2>
            <p className="poppins-regular" style={{ fontSize: '1.5rem' }}>
              TechConnect Foundation is dedicated to bridging the digital divide by providing refurbished computers to individuals, families, and nonprofits in need.
            </p>
          </Col>
        </Row>

        {/* Fifth Section: Testimonials */}
        <Row className="align-items-start mt-7 justify-content-lg-center" style={{ marginTop: '80px', padding: '50px 0' }}>
          {/* Left Column: Testimonials */}
          <Col md={7} className="d-flex flex-column" style={{ padding: '0 50px' }}>
            {/* Section Title (Perfectly Aligned with Partners & Our Mission) */}
            <h2 className="poppins-bold" style={{ fontSize: '2rem', marginBottom: '30px' }}>
              Testimonials
            </h2>

            {/* Content Wrapper for Image + First Quote */}
            <Row className="align-items-center" style={{ marginBottom: '30px' }}>
              <Col md="auto">
                <img src="images/kidlaptop.jpeg" alt="Recipient with Laptop" 
                  style={{ width: '250px', height: '250px', objectFit: 'cover', borderRadius: '10px' }} />
              </Col>
              <Col>
                <blockquote className="poppins-regular" 
                  style={{ fontStyle: 'italic', fontSize: '1.5rem', lineHeight: '1.6', margin: '0' }}>
                  “Here's the picture of J with the computer! Thank you so much!!!!!!!”
                </blockquote>
              </Col>
            </Row>

            {/* Second Testimonial */}
            <blockquote className="poppins-regular" 
              style={{ fontStyle: 'italic', fontSize: '1.5rem', lineHeight: '1.6', borderLeft: '5px solid #007bff', paddingLeft: '20px', marginBottom: '20px' }}>
              "Thank you to <strong>TechConnect Foundation</strong> for thinking of us and donating computers for kids who can really use them."
            </blockquote>
          </Col>

          {/* Right Column: Embedded Twitter Post (Same Height as Testimonials) */}
          <Col md={3} className="d-flex flex-column align-items-center justify-content-center">
            <TwitterTweetEmbed tweetId="1859378692914328030" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Main;